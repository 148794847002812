import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import * as aboutStyles from "../styles/about.module.css"

export default function About() {
	return (
		<Layout>
			<main className={aboutStyles.aboutContentWrapper}>
				<div className="container">
					<div className="row justify-content-center">
						<div className={`col-md-5 ${aboutStyles.aboutImage}`}>
							<StaticImage src="../images/about.png" layout="fixed" className="mx-auto" alt="Humaira Tasnim"/>
						</div>
						<div className="col-md-7">
							<h1>Hi there!</h1>
							<p>I'm Humaira — a designer-developer hybrid specializing in user experience design, front-end development, and digital accessibility. I'm passionate about crafting engaging experiences and practical user interfaces that solve complex problems through empathy and research.</p>
							<p>With my design + systems background, I am able to connect the dots between business requirements and technical constraints ensuring every design decision supports the project strategy and goals—all while relentlessly advocating for the user.</p>
							<p>I recently graduated with a Masters in UX Design from the University of Toronto. Before this, I worked across various development roles after completing my undergrad in Information Systems from Carnegie Mellon University.</p>
							<p>Although I still enjoy coding, I got into product design because I was intrigued by the idea of uncovering and absolving pain points for users so they can better accomplish their goals. Being able to design meaningful experiences that add value to people's lives is what I love about this field!</p>
							<p>Currently, I'm working as a <strong>Senior Product Designer at Rogers</strong> improving the digital self-serve experience for telecom customers.</p>
							<p><strong>Want to know more?</strong> <a href="https://drive.google.com/file/d/1-wvxsmVsd9dtY8KqOMrX8MVYUoNXvE8C/view?usp=sharing" target="_blank" rel="noreferrer">Check out my résumé →</a></p>
						</div>
					</div>
				</div>
			</main>
		</Layout>
	)
}
